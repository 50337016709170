import {SET_CENTER, CLEAR_STATE, SET_ZOOM} from './actions';
import env from '../config/env';

const initialState = {
    center: null,
    zoom: null
}

const centerState = (state = initialState, action) => {
    switch(action.type){
        case SET_CENTER:
            return {
                ...state,
                center: action.payload
            };
        case SET_ZOOM:
            return {
                ...state,
                zoom: action.payload
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}

export default centerState;