import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer } from '../components';
import {getUser, getDayReport, setLoginState, clearState} from "../store/actions";
import HeaderTitle from "../atoms/HeaderTitle";
import {Link} from "react-router-dom";
import LoginFilter from "../atoms/LoginFilter";


const TopPage = props => {
  const dispatch = useDispatch();

  const { arg1 } = useParams();
  
  const history = useHistory();

  const [dayReport, setDayReport] = useState(null);
  const [targetDate, setTargetDate] = useState(null);

  
  const loadDayReport = async (targetDate) => {
 
    const response = await dispatch(getDayReport(targetDate))
    .then(res => {
      return res;
    }).catch(err => {

      // history.push("/login")
    });


    return response
  }
  
  const moveAnotherDate = async (argTargetDate) => {

    const res = await loadDayReport(argTargetDate);
    setDayReport(res)
  }
  
  useEffect(() => {

    // useEffectで非同期関数を呼び
    (async () => {

      /*
       * 初期値をセット
       */

      const hiduke = new Date();
      const year   = hiduke.getFullYear();
      const month  = (hiduke.getMonth() + 1).toString().padStart(2, "0"); // ゼロ埋め
      const day    = hiduke.getDate().toString().padStart(2, "0"); // ゼロ埋め

      const todayDate = year + "-" + month + "-" + day;
      
      let res = null;
      
      if (arg1) {

        setTargetDate(arg1);
        res = await loadDayReport(arg1);
      } else {
        setTargetDate(todayDate);
        res = await loadDayReport(todayDate);
      }
      
      // const res = await loadDayReport(todayDate);

      setDayReport(res)

    })();

    
  }, [])
  
  return (
    <div className="l-wrap l-wrap--home">

      <LoginFilter />
      <HeaderTitle />
      <MyAnalytics />

      <div className="l-home-main">
        <ul className="p-head-date">
          
          {/* 前の日付 */}
          
          {
            (dayReport && dayReport.prev_date) && (
              <li onClick={() => moveAnotherDate(dayReport.prev_date)} className="p-head-date-l"><i className="icon icon-arrow-l"></i></li>
            )
          }
          
          <li className="p-head-date-c">
            <span>{ dayReport && dayReport.report_date_y }</span>年
            <span>{ dayReport && dayReport.report_date_m }</span>月
            <span>{ dayReport && dayReport.report_date_d }</span>日
          </li>

           {/*次の日付 */}
          {
            (dayReport && dayReport.next_date) && (
              <li onClick={() => moveAnotherDate(dayReport.next_date)}  className="p-head-date-r"><i className="icon icon-arrow-r"></i></li>
            )
          }
          
        </ul>
        <div className="l-home-main__in">
          <div className="p-hainyou">
            <dl className="p-hainyou-ml">
              <dt>排尿状況</dt>
              <dd><span className="__values">{ dayReport && dayReport.output_value }</span><span className="__ml">mL</span></dd>
            </dl>
            <ul className="p-hainyou-figures">
              <li>
                <ul className="__times">
                  <li><i className="icon icon-urine"></i><span className="u-fs-16">{ dayReport && dayReport.output_count }</span>回</li>
                  <li><i className="icon icon-moon"></i><span className="u-fs-16">{ dayReport && dayReport.asleep_output_percentage }</span>%</li>
                </ul>
              </li>
              <li><i className="icon icon-time"></i><span className="u-fs-16">{ dayReport && dayReport.last_time }</span></li>
            </ul>
          </div>
          <div className="u-pb-4">
            <p className="p-intake"><i className="icon icon-drink u-clr-light u-fs-16"></i><span
              className="u-clr-sky u-pe-1">水分摂取量</span><span className="u-fs-15">{ dayReport && dayReport.input_value }</span>mL</p>
          </div>
        </div>
      </div>


      <div className="l-oneday">


        <div className="l-oneday__bar">
          <ul className="p-time-list">
            <li>
              <div className="__items">
                <ul className="p-time-list-item">
                  <li></li>
                  <li><i className="icon icon-time u-clr-txt u-fs-16"></i></li>
                  <li><i className="icon icon-urine u-clr-urine u-fs-16"></i></li>
                  <li><i className="icon icon-drink u-clr-drink u-fs-16 __drink"></i></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>


        <div className="l-oneday__block">

          <div className="l-oneday__list">
            <ul className="p-time-list">

              {
                (dayReport && dayReport.reports) && 

                    dayReport.reports.map(report => {
                       return (
                    <li>
                    <Link to={"/manual/"+report.id}>
                    <ul className="p-time-list-item">
                    <li></li>
                    <li><span className="u-clr-sky">{ report.time }</span></li>
                    <li><span className="u-clr-txt">{ report.report_type == "1" ? report.output_value + "mL" : "" }</span></li>
                    <li><span className="u-clr-txt">{ report.report_type == "2" ? report.input_value + "mL" : "" }</span></li>
                    </ul>
                    </Link>
                    </li>
                    )
                  }
                )
              }
              
            </ul>
          </div>
        </div>
      </div>
      
      
      
      
      
      <div className="l-foot-btn">
        <div className="l-foot-btn--in">
          <div className="u-txt-c u-mb-xxs">
            <button className="c-btn c-btn-solid c-btn-xl c-icon-btn" onClick={() => {history.push("/camera")}}><i
              className="icon icon-camera-line u-fs-18 u-pe-1"></i><span>排尿量撮影</span></button>
          </div>
          <div className="u-txt-c">
            
            <ul className="p-btn-record">
              
              <li>
                <button type={"button"} onClick={() => {history.push("/history")}} className="c-btn c-btn-line c-btn-xl c-icon-btn"><i
                  className="icon icon-calendar u-fs-18 u-pe-1"></i><span>過去記録</span></button>
              </li>
              
              <li>
                <button type={"button"} onClick={() => history.push("/manual") } className="c-btn c-btn-line c-btn-xl c-icon-btn"><i
                  className="icon icon-pen u-fs-18 u-pe-1"></i><span>記録</span></button>
              </li>
              
            </ul>

          </div>
        </div>
      </div>
      
      <PageTopButton />
    </div>
  )
}

export default TopPage;
