import React from 'react';
import { useHistory } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";

const UserRegister = props => {
    const history = useHistory();
    
    return (
      <div className="l-wrap">
        <MyAnalytics />

        <HeaderTitle />

        <div className="l-users is-first">
          <div className="l-users--in">

            <h1 className="p-ttl">パスワード再設定</h1>
            <p className="u-txt-c u-pb-1 p-mail-icon"><i className="icon icon-mail"></i></p>
            <p className="u-txt-c u-pb-6">メールの確認をお願いします。</p>
            <hr className="u-hr u-mb-7" />
              <p className="u-pb-3">パスワード再発行の手続きのご案内を指定のメールアドレスへ送信しました。</p>
              <p>メール内容をご確認いただき、パスワード再発行をおこなってください。</p>
          </div>
        </div>
          
      </div>
    )
}

export default UserRegister;
