import React, {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import {
    Login,
    TopPage,
    Camera,
    ManualInput,
    History,
    List,
    Detail,
    TermOfService,
    UserRegister,
    UserRegisterTemporaryComplete, 
    UserRegisterComplete,
    PasswordMail,
    PasswordMailComplete,
    PasswordRegenerate,
    PasswordRegenerateComplete
} from '../screens';

const MyRouter = props => {
    const isLogin = useSelector(state => state.login.isLogin);

    return (
        <>
            <Router basename={process.env.PUBLIC_URL}>
                <Route exact path='/'>
                    {isLogin ? <Redirect to="/top" /> : <Login />}
                </Route>
                <Route path='/login'>
                    {isLogin ? <Redirect to="/top" /> : <Login />}
                </Route>
                <Route exact path='/top' >
                    <TopPage />
                </Route>
                <Route path='/top/:arg1'>
                    <TopPage />
                </Route>
                <Route path='/camera'>
                    <Camera />
                </Route>
                <Route exact path='/manual'>
                    <ManualInput />
                </Route>
                <Route path='/manual/:reportId'>
                    <ManualInput />
                </Route>
                <Route path='/manual_with_photo/:photoId/:tmpOutputValue'>
                    <ManualInput />
                </Route>
                <Route path='/term_of_service'>
                    <TermOfService />
                </Route>
                <Route path='/user_register'>
                    <UserRegister />
                </Route>
                <Route path='/user_register_complete'>
                    <UserRegisterComplete />
                </Route>
                <Route path='/user_register_temporary_complete'>
                    <UserRegisterTemporaryComplete />
                </Route>
                <Route path='/history'>
                    <History />
                </Route>
                <Route path='/password_regenerate/index'>
                    <PasswordMail />
                </Route>
                <Route path='/password_regenerate/mail_complete'>
                    <PasswordMailComplete />
                </Route>
                <Route path='/password_regenerate/input/:email/:argToken/'>
                    <PasswordRegenerate />
                </Route>
                <Route path='/password_regenerate/complete'>
                    <PasswordRegenerateComplete />
                </Route>
            </Router>
        </>
    )
}

export default MyRouter;
