// APIのURL
// const IS_PC=true
// const localAPI = 'http://localhost:9767';
// const baseURL ='http://127.0.0.1:6992'
// const loginUrl ='http://localhost:9767/oauth/token'

// デモ環境
// const IS_PC=false
// const localAPI = 'https://demo2-ishidamedical.datacradle.net/server';
// const baseURL ='https://area.bousai-dataeye.jp'
// const loginUrl ='https://demo2-ishidamedical.datacradle.net/server/oauth/token'

// 本番環境
const IS_PC=false
const localAPI = 'https://uronote.ishidamedical.com/server';
const baseURL ='https://area.bousai-dataeye.jp'
const loginUrl ='https://uronote.ishidamedical.com/server/oauth/token'

// 旧デモ環境
// const IS_PC=false
// const localAPI = 'https://demo-ishidamedical.datacradle.net/server';
// const baseURL ='https://area.bousai-dataeye.jp'
// const loginUrl ='https://demo-ishidamedical.datacradle.net/server/oauth/token'



const CLIENT_ID ='2'
const CLIENT_SECRET ='bK0m6mgBrONLRJ374BNSu0ZxBsFaTVzdaBjva1D7'

const GOOGLE_ANALYTICS_ID = ""

export default {
  IS_PC,
  localAPI,
  baseURL,
  GOOGLE_ANALYTICS_ID,
  loginUrl,
  CLIENT_ID,
  CLIENT_SECRET
}
