import React, {useState} from 'react';
import { useHistory } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";
import {sendPasswordRegenerationMail} from "../store/actions";
import {useDispatch} from "react-redux";


const UserRegister = props => {

  const history = useHistory();

  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const regeneratePassword = async () => {

    // エラーメッセージをリセット
    setEmailError(null);
    
    const response = await dispatch(sendPasswordRegenerationMail(email))
    .then(res => {
      return res;
    }).catch(err => {
      return "error";
    });

    if (response === "failed" || response === "error") {
      setEmailError("システムでエラーが発生しました。")
    }

    if (response.errors) {

      if (response.errors.email) {
        setEmailError(response.errors.email)
      }
      
    } else {
      history.push("/password_regenerate/mail_complete");
    }
  }
  
  return (
      <div className="l-wrap">
        <MyAnalytics />

        <HeaderTitle />
        
        <div className="l-users is-first">
          <div className="l-users--in">
            <div className="row">

              <h1 className="p-ttl">パスワード再設定</h1>
              <form>
                <ul>
                  <li className="u-pb-6">パスワードの再設定を行います。ご登録のメールアドレスを入力してください。</li>
                  <li className="u-pb-2">
                    <div>
                      <input type="email" placeholder="メールアドレス" value={email} onChange={e => setEmail(e.target.value)} className="f-w-lg" />
                    </div>

                    { emailError && <span className="f-error">{ emailError }</span> }
                    
                  </li>
                </ul>

                <div className="u-txt-c">
                  <button  type={"button"} onClick={() => regeneratePassword()} className="c-btn c-btn-solid c-btn-lg">送信</button>
                </div>
                
              </form>

            </div>
          </div>
        </div>
         
          
      </div>
    )
}

export default UserRegister;
