import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer } from '../components';

import {getDayReportList} from "../store/actions";
import HeaderTitle from "../atoms/HeaderTitle";
import {Link} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import LoginFilter from "../atoms/LoginFilter";

const History = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hainyoDataState = useSelector(state => state.hainyoData.data)


  const [isLoaded, setIsLoaded] = useState(false)
  
  const [items, setItems] = useState([])
  const [hasMore, setHasMore] = useState(true);

  const [dayReportList, setDayReportList] = useState([]);
  const [dbList, setDBList] = useState([]);

  const [total, setTotal] = useState(0);

  /**
   * ajaxを使って全件をまず取得する。
   * @param targetDate
   * @returns {Promise<*>}
   */
  const loadDayReport = async (targetDate) => {

    const response = await dispatch(getDayReportList(targetDate))
    .then(res => {
      return res;
    }).catch(err => {
      // history.push("/login")
    });

    return response
  }

  /**
   * 無限スクロールにより、表示部分を増やしていく処理
   */
  const loadFunc = () => {
    const i = dayReportList.length + 10
    console.log(hasMore)
    console.log(i, dayReportList.length, total)
    if (i >= total && dayReportList.length >= total) {
      setHasMore(false)
      return
    }

    setDayReportList(dbList.slice(0, i - 1))
  }
  

  useEffect(() => {

    // useEffectで非同期関数を呼び
    (async () => {
      
      const res = await loadDayReport();
      
      setTotal(res.length)
      
      setDBList(res)
      
      setIsLoaded(true);

    })();
    
    if (isLoaded) {
      loadFunc();
    }
    
  }, [])
  
  
    const hainyoSummary = (record) => {
        const hainyo = record.filter(record => record.type=="hainyo")
        const hainyo_count = hainyo.length
        const hainyo_amount = hainyo.reduce((sum, element) => sum + element.amount, 0)
        
        const night_count = record.filter(record => record.night == true) | 0
        const night_rate = (hainyo_count/night_count) * 100 | 0
        
        const suibun = record.filter(record => record.type=="suibun")
        const suibun_amount = suibun.reduce((sum, element) => sum + element.amount, 0)
        return(`${hainyo_amount}mL/${hainyo_count}回 ${night_rate}%/${night_count}回 ${suibun_amount}`)
    }

    return (
      <div className="l-wrap l-wrap--home">
        <LoginFilter />
        
            <MyAnalytics />
        
        <header className="l-header">
          <div className="l-header__in">
            <ul className="p-head">
              <li className="p-head-l"><Link to={"/top"}><i className="icon icon-xmark"></i></Link></li>
              <li className="p-head-c">過去記録</li>
            </ul>
          </div>
        </header>

        <div className="l-list">
          <div className="l-hanrei">
            <ul className="p-hanrei">
              <li><i className="icon icon-urine u-clr-urine u-me-1"></i><span
                className="u-fs-10">総排尿量／総排尿回数</span></li>
              <li><i className="icon icon-moon u-me-1"></i><span
                className="u-fs-10">就寝中の排尿量の割合／夜間排尿回数</span></li>
              <li><i className="icon icon-drink u-clr-drink u-me-1"></i><span className="u-fs-10">総水分摂取量</span>
              </li>
            </ul>
          </div>

          <div className="l-archive">

            { isLoaded &&
            
            <InfiniteScroll
              pageStart={0}
              loadMore={loadFunc}
              hasMore={hasMore}
              loader={<div className="loader" key={0}>Loading ...</div>}
            >
              
            <ul className="p-archive">

              {
                dayReportList && dayReportList.map(dayReport => {
                  return (<li>
                    <Link to={"/top/"+dayReport.report_date}>
                      <dl>
                        <dt><span className="u-clr-sky">{ dayReport.report_date }</span></dt>
                        <dd>
                          <ul className="p-allday-data">
                            <li className="u-me-2"><i className="icon icon-urine u-clr-urine u-fs-15"></i><span>{ dayReport.output_value }</span>mL
                              ／ <span>{ dayReport.output_count }</span>回
                            </li>
                            <li className="u-me-2"><i className="icon icon-moon u-fs-15"></i><span>{ dayReport.asleep_output_percentage }</span>%
                              ／ <span>{ dayReport.night_count }</span>回
                            </li>
                            <li><i className="icon icon-drink u-clr-drink u-fs-15"></i><span>{ dayReport.input_value }</span>mL</li>
                          </ul>
                        </dd>
                      </dl>
                    </Link>
                  </li>)
                })
                
              }
              
              
            </ul>

            </InfiniteScroll>
            
            }
          </div>
        </div>


      </div>
    )
}

export default History;
