import {TOGGLE_HANREI_VISIBLE, CLEAR_STATE} from './actions';

const initialState = {
    visibility: true
}

const hanreiState = (state = initialState, action) => {
    switch(action.type){
        case TOGGLE_HANREI_VISIBLE:
            return {
                ...state,
                visibility: action.payload
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state
    }
}

export default hanreiState;