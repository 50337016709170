import {SET_FORMAT_FILE_CATEGORIES, CLEAR_STATE} from './actions';

const initialState = {
    categories: [],
}

const formatFileState = (state = initialState, action) => {
    switch(action.type){
        case SET_FORMAT_FILE_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state
    }
}

export default formatFileState;
