import React, {useState} from 'react';
import { useHistory } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";
import {Link} from "react-router-dom";
import {login, registerUser} from "../store/actions";
import {useDispatch} from "react-redux";

const UserRegister = props => {
    const history = useHistory();

    const dispatch = useDispatch();

    const sexList = [
        {
            label: "男性",
            value: "male"
        },
        {
            label: "女性",
            value: "female"
        }
    ]


    const ageList = [
        {
            label: "10代",
            value: "age10"
        },
        {
            label: "20代",
            value: "age20"
        },
        {
            label: "30代",
            value: "age30"
        },
        {
            label: "40代",
            value: "age40"
        },
        {
            label: "50代",
            value: "age50"
        },
        {
            label: "60代",
            value: "age60"
        },
        {
            label: "70代",
            value: "age70"
        },
        {
            label: "80代",
            value: "age80"
        },
        {
            label: "90代以上",
            value: "age90"
        },
    ]

    const prefectureList = [
        { "value": 1, "label": "北海道"},
        { "value": 2, "label": "青森県"},
        { "value": 3, "label": "岩手県"},
        { "value": 4, "label": "宮城県"},
        { "value": 5, "label": "秋田県"},
        { "value": 6, "label": "山形県"},
        { "value": 7, "label": "福島県"},
        { "value": 8, "label": "茨城県"},
        { "value": 9, "label": "栃木県"},
        { "value": 10, "label": "群馬県"},
        { "value": 11, "label": "埼玉県"},
        { "value": 12, "label": "千葉県"},
        { "value": 13, "label": "東京都"},
        { "value": 14, "label": "神奈川県"},
        { "value": 15, "label": "新潟県"},
        { "value": 16, "label": "富山県"},
        { "value": 17, "label": "石川県"},
        { "value": 18, "label": "福井県"},
        { "value": 19, "label": "山梨県"},
        { "value": 20, "label": "長野県"},
        { "value": 21, "label": "岐阜県"},
        { "value": 22, "label": "静岡県"},
        { "value": 23, "label": "愛知県"},
        { "value": 24, "label": "三重県"},
        { "value": 25, "label": "滋賀県"},
        { "value": 26, "label": "京都府"},
        { "value": 27, "label": "大阪府"},
        { "value": 28, "label": "兵庫県"},
        { "value": 29, "label": "奈良県"},
        { "value": 30, "label": "和歌山県"},
        { "value": 31, "label": "鳥取県"},
        { "value": 32, "label": "島根県"},
        { "value": 33, "label": "岡山県"},
        { "value": 34, "label": "広島県"},
        { "value": 35, "label": "山口県"},
        { "value": 36, "label": "徳島県"},
        { "value": 37, "label": "香川県"},
        { "value": 38, "label": "愛媛県"},
        { "value": 39, "label": "高知県"},
        { "value": 40, "label": "福岡県"},
        { "value": 41, "label": "佐賀県"},
        { "value": 42, "label": "長崎県"},
        { "value": 43, "label": "熊本県"},
        { "value": 44, "label": "大分県"},
        { "value": 45, "label": "宮崎県"},
        { "value": 46, "label": "鹿児島県"},
        { "value": 47, "label": "沖縄県"}
    ]

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [emailConfirmationError, setEmailConfirmationError] = useState("");

    
    
    const [email, setEmail] = useState("");
    const [emailConfirmation, setEmailConfirmation] = useState("");
    const [password, setPassword] = useState("");

    const [sex, setSex] = useState("");
    const [age, setAge] = useState("");
    const [prefectureId, setPrefectrueId] = useState("");
    
    
    const execLogin = async () => {
        
        // エラーメッセージをリセット
        setEmailError(null);
        setEmailConfirmationError(null);
        setPasswordError(null);
        
        
        const response = await dispatch(registerUser(email, emailConfirmation, password, sex, age, prefectureId))
        .then(res => {
            return res;
        }).catch(err => {
            return "error";
        });
        
        if (response === "failed" || response === "error") {
            setEmailError("システムでエラーが発生しました。")
        }

        if (response.errors) {

            if (response.errors.email) {
                setEmailError(response.errors.email)
            }

            if (response.errors.email_confirmation) {
                setEmailConfirmationError(response.errors.email_confirmation)
            }

            if (response.errors.password) {
                setPasswordError(response.errors.password)
            }

        } else {
            history.push("/user_register_temporary_complete");
        }
    }
    
    return (
      <div className="l-wrap">
        <MyAnalytics />

        <HeaderTitle />

          <div className="l-users is-first">
              <div className="l-users--in">

                  <h1 className="p-ttl">新規登録</h1>
                  <form>
                      <ul>
                          <li className="u-pb-2">

                              <div>
                                  <input type="email" placeholder="メールアドレス" value={email} onChange={e => setEmail(e.target.value)} className="f-w-lg" />
                              </div>

                              { emailError && <span className="f-error">{ emailError }</span> }
                              
                          </li>
                          <li className="u-pb-2">

                              <div>
                                  <input type="email" placeholder="メールアドレスの再入力" value={emailConfirmation} onChange={e => setEmailConfirmation(e.target.value)} className="f-w-lg" />
                              </div>

                              { emailConfirmationError && <span className="f-error">{ emailConfirmationError }</span> }
                              
                          </li>
                          <li className="u-pb-2">
                              <div>
                                  <input type="password" placeholder="パスワード" value={password} onChange={e => setPassword(e.target.value)} className="f-w-lg" />
                              </div>

                              { passwordError && <span className="f-error">{ passwordError }</span> }
                              
                          </li>

                          <li>
                              <dl className="p-dl-list is-form">
                                  <dt>性別</dt>
                                  <dd>
                                      <div className="f-radio">
                                          <p>
                                              {sexList.map(radio => {
                                                  return (

                                                    <>
                                                        {/* checked属性に式を定義する */}
                                                        <input type="radio" name="sex" id={radio.value}
                                                               value={radio.value} checked={radio.value === sex} onChange={(e) => setSex(e.target.value)}/>
                                                        <label for={radio.value}>{radio.label}</label>
                                                    </>
                                                  )
                                              })}
                                          </p>
                                      </div>
                                  </dd>
                              </dl>
                          </li>
                          <li>
                              <dl className="p-dl-list is-form">
                                  <dt>年齢</dt>
                                  <dd>
                                      <p className="f-select">
                                          <select value={age} onChange={(e) => setAge(e.target.value)} name="sample-1">
                                              {ageList.map(radio => {
                                                  return (

                                                    <>
                                                        {/* checked属性に式を定義する */}
                                                        <option name="age" id={radio.value}
                                                                value={radio.value} selected={radio.value === age} >
                                                            { radio.label }
                                                        </option>
         
                                                    </>
                                                  )
                                              })}
                                          </select>
                                      </p>
                                  </dd>
                              </dl>
                          </li>
                          <li className="u-pb-4">
                              <dl className="p-dl-list is-form">
                                  <dt>居住地</dt>
                                  <dd>
                                      <p className="f-select">
                                          <select value={prefectureId} onChange={(e) => setPrefectrueId(e.target.value)} name="sample-1">
                                              {prefectureList.map(radio => {
                                                  return (

                                                    <>
                                                        {/* checked属性に式を定義する */}
                                                        <option name="age" id={radio.value}
                                                                value={radio.value} selected={radio.value === age} >
                                                            { radio.label }
                                                        </option>

                                                    </>
                                                  )
                                              })}
                                          </select>
                                      </p>
                                  </dd>
                              </dl>
                          </li>
                          
                      </ul>

                      <div className="u-txt-c u-mb-md">
                          <button type={"button"} onClick={() => execLogin()} className="c-btn c-btn-solid c-btn-lg">登録</button>
                      </div>

                      <div className="u-txt-c">
                          <Link to={"/login"}>すでに登録済みですか？ログイン</Link>
                      </div>

                  </form>

              </div>
          </div>
                   
      </div>
    )
}

export default UserRegister;
