import {SET_CIRCLE_POSITION, CLEAR_STATE} from './actions';

const initialState = {
    center: null
}

const circleState = (state = initialState, action) => {
    switch(action.type){
        case SET_CIRCLE_POSITION:
            return {
                ...state,
                center: action.payload
            };
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}

export default circleState;