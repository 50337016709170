import hinanjoIcon from '../assets/img/icon_hinanjyo.png';
import gasIcon from '../assets/img/icon_h-gas.png';
import carIcon from '../assets/img/icon_h-car-rental.png';
import bankIcon from '../assets/img/icon_h-bank.png';
import hotelIcon from '../assets/img/icon_h-hotel.png';
import stationIcon from '../assets/img/icon_h-station.png';
import laundryIcon from '../assets/img/icon_h-laundry.png';
import convenienceIcon from '../assets/img/icon_h-convenience.png';
import superIcon from '../assets/img/icon_h-supermarket.png';
import restaurantIcon from '../assets/img/icon_h-restaurant.png';
import drugstoreIcon from '../assets/img/icon_h-drag-store.png';
import homecenterIcon from '../assets/img/icon_h-home-center.png';
import clothIcon from '../assets/img/icon_h-clothing-store.png';
import parkIcon from '../assets/img/icon_h-park.png';
import babyIcon from '../assets/img/icon_h-baby.png';
import welfareIcon from '../assets/img/icon_h-welfare.png';
import pharmacyIcon from '../assets/img/icon_h-pharmacy.png';
import hospitalIcon from '../assets/img/icon_h-hospital.png';
import rShelter from '../assets/img/marker/m_r-shelter.png';
import eShelter from '../assets/img/marker/m_e-shelter.png';
import wShelter from '../assets/img/marker/m_w-shelter.png';
import mPlace from '../assets/img/marker/m_m-place.png';
import park from '../assets/img/marker/m_park.png';
import speaker from '../assets/img/marker/m_speaker.png';
import camera from '../assets/img/marker/m_camera.png';
import shoukasen from '../assets/img/marker/m_shoukasen.png';
import boukasuisou from '../assets/img/marker/m_boukasuisou.png';
import shoukaki from '../assets/img/marker/m_shoukaki.png';
import shobokiko from '../assets/img/marker/m_shobokiko.png';
import hospital from '../assets/img/marker/m_hospital.png';
import aed from '../assets/img/marker/m_aed.png';
import garbageStation from '../assets/img/marker/m_garbage-station.png';
import bbs from '../assets/img/marker/m_bbs.png';
import publicPhone from '../assets/img/marker/m_public-phone.png';
import vendingMachine from '../assets/img/marker/m_vending-machine.png';
import waterWray from '../assets/img/marker/m_waterwray.png';
import dArea from '../assets/img/marker/m_d-area.png';
import dRoad from '../assets/img/marker/m_d-road.png';
import hazardousArea from '../assets/img/marker/m_hazardous-area.png';
import n01 from '../assets/img/marker/m_n01.png';
import n02 from '../assets/img/marker/m_n02.png';
import n03 from '../assets/img/marker/m_n03.png';
import n04 from '../assets/img/marker/m_n04.png';
import n05 from '../assets/img/marker/m_n05.png';
import n06 from '../assets/img/marker/m_n06.png';
import n07 from '../assets/img/marker/m_n07.png';
import n08 from '../assets/img/marker/m_n08.png';
import n09 from '../assets/img/marker/m_n09.png';
import n10 from '../assets/img/marker/m_n10.png';
import n11 from '../assets/img/marker/m_n11.png';
import n12 from '../assets/img/marker/m_n12.png';
import n13 from '../assets/img/marker/m_n13.png';
import n14 from '../assets/img/marker/m_n14.png';
import n15 from '../assets/img/marker/m_n15.png';
import n16 from '../assets/img/marker/m_n16.png';
import n17 from '../assets/img/marker/m_n17.png';
import n18 from '../assets/img/marker/m_n18.png';
import n19 from '../assets/img/marker/m_n19.png';
import n20 from '../assets/img/marker/m_n20.png';
import evacuationShelter from '../assets/img/marker/m_evacuation-shelter.png';
import reservoir from '../assets/img/marker/m_reservoir.png';
import rShelterP from '../assets/img/marker/m_r-shelter_p.png';
import eShelterP from '../assets/img/marker/m_e-shelter_p.png';
import wShelterP from '../assets/img/marker/m_w-shelter_p.png';
import mPlaceP from '../assets/img/marker/m_m-place_p.png';
import parkP from '../assets/img/marker/m_park_p.png';
import speakerP from '../assets/img/marker/m_speaker_p.png';
import cameraP from '../assets/img/marker/m_camera_p.png';
import shoukasenP from '../assets/img/marker/m_shoukasen_p.png';
import boukasuisouP from '../assets/img/marker/m_boukasuisou_p.png';
import shoukakiP from '../assets/img/marker/m_shoukaki_p.png';
import shobokikoP from '../assets/img/marker/m_shobokiko_p.png';
import hospitalP from '../assets/img/marker/m_hospital_p.png';
import aedP from '../assets/img/marker/m_aed_p.png';
import garbageStationP from '../assets/img/marker/m_garbage-station_p.png';
import bbsP from '../assets/img/marker/m_bbs_p.png';
import publicPhoneP from '../assets/img/marker/m_public-phone_p.png';
import vendingMachineP from '../assets/img/marker/m_vending-machine_p.png';
import waterWrayP from '../assets/img/marker/m_waterwray_p.png';
import dAreaP from '../assets/img/marker/m_d-area_p.png';
import dRoadP from '../assets/img/marker/m_d-road_p.png';
import hazardousAreaP from '../assets/img/marker/m_hazardous-area_p.png';
import n01P from '../assets/img/marker/m_n01_p.png';
import n02P from '../assets/img/marker/m_n02_p.png';
import n03P from '../assets/img/marker/m_n03_p.png';
import n04P from '../assets/img/marker/m_n04_p.png';
import n05P from '../assets/img/marker/m_n05_p.png';
import n06P from '../assets/img/marker/m_n06_p.png';
import n07P from '../assets/img/marker/m_n07_p.png';
import n08P from '../assets/img/marker/m_n08_p.png';
import n09P from '../assets/img/marker/m_n09_p.png';
import n10P from '../assets/img/marker/m_n10_p.png';
import n11P from '../assets/img/marker/m_n11_p.png';
import n12P from '../assets/img/marker/m_n12_p.png';
import n13P from '../assets/img/marker/m_n13_p.png';
import n14P from '../assets/img/marker/m_n14_p.png';
import n15P from '../assets/img/marker/m_n15_p.png';
import n16P from '../assets/img/marker/m_n16_p.png';
import n17P from '../assets/img/marker/m_n17_p.png';
import n18P from '../assets/img/marker/m_n18_p.png';
import n19P from '../assets/img/marker/m_n19_p.png';
import n20P from '../assets/img/marker/m_n20_p.png';
import evacuationShelterP from '../assets/img/marker/m_evacuation-shelter_p.png';
import reservoirP from '../assets/img/marker/m_reservoir_p.png';

// マップの設定
const mapConfig = {
    zoom: 14,
    minZoom: 6,
    // maxZoom: 18, maxzoomは各タイルレイヤーで指定する。
    center: {
      lat: 34.584661,
      lng: 133.77228
    },
    gestureHandling: true,
    preferCanvas: true,
    zoomControl: true,
    scrollWheelZoom: true,
    baseLayers: [
      {
        name: "OpenStreetMap",
        attribution: '&copy; <a href="//osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        checked: false,
        maxZoom: 22, // 実際の最大zoomの画像をどこまで拡大して擬似zoomを行うか。
        maxNativeZoom: 19 //実際の最大zoom
      },
      {
        name: "国土地理院 - 淡色",
        attribution: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>",
        url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
        checked: true,
        maxZoom: 22,// 実際の最大zoomの画像をどこまで拡大して擬似zoomを行うか。
        maxNativeZoom: 18//実際の最大zoom
      },
      {
        name: "国土地理院 - 標準地図",
        attribution: "&copy; <a href='https://maps.gsi.go.jp/development/ichiran.html' target=\'_blank\'>地理院タイル</a>",
        url: "//cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
        checked: false,
        maxZoom: 22,// 実際の最大zoomの画像をどこまで拡大して擬似zoomを行うか。
        maxNativeZoom: 18//実際の最大zoom
      },
    ]
  }
  
  // 各マップのタイトル設定
  const headerTitle = {
    "bousai": "地区防災マップ",
    "spotregist": "スポットを登録",
    "freedrawing": "避難ルートを手書き登録"
  }
  
  // 扱うマーカのカテゴリリスト マーカデータフェッチとstoreの初期化に用いる
  const markerCategories = [
    "tameike_kurashiki",
    "hinanjo",
    "shoukasen",
    "boukasuisou",
    "syuuhenshisetsu",
    "hisaisha_shien"
  ]
  
  // マーカカテゴリごとのアイコン
  const markerCategory2Img = {
    'ため池': reservoir,
    '消火栓': shoukasen,
    '防火水そう': boukasuisou,
    '避難所': hinanjoIcon,
    'ガソリンスタンド': gasIcon,
    'レンタカー': carIcon,
    '銀行': bankIcon,
    '宿泊施設': hotelIcon,
    '駅・バス停': stationIcon,
    'コインランドリー': laundryIcon,
    'コンビニエンスストア': convenienceIcon,
    'スーパーマーケット': superIcon,
    '飲食店': restaurantIcon,
    'ドラッグストア': drugstoreIcon,
    'ホームセンター': homecenterIcon,
    '衣料品店': clothIcon,
    '公園': parkIcon,
    '赤ちゃんの駅': babyIcon,
    '福祉施設': welfareIcon,
    '薬局': pharmacyIcon,
    '病院': hospitalIcon,
    'default': clothIcon
  }
  
  // 各ページで扱うマーカの一覧
  const markersInUse = {
    "bousai": ["hinanjo", "tameike_kurashiki","boukasuisou","shoukasen"]
  }
  
  const newSpotList = [
    { id: "cbmy-01", src: rShelter, name: "届出避難所", type: "rShelter" },
    { id: "cbmy-02", src: eShelter, name: "緊急避難所", type: "eShelter" },
    { id: "cbmy-03", src: wShelter, name: "福祉避難所", type: "wShelter" },
    { id: "cbmy-04", src: mPlace, name: "集会所", type: "mPlace" },
    { id: "cbmy-05", src: park, name: "公園", type: "park" },
    { id: "cbmy-06", src: speaker, name: "拡声塔", type: "speaker" },
    { id: "cbmy-07", src: camera, name: "防犯カメラ", type: "camera" },
    { id: "cbmy-08", src: shoukasen, name: "消火栓", type: "shoukasen" },
    { id: "cbmy-09", src: boukasuisou, name: "防火水そう", type: "boukasuisou" },
    { id: "cbmy-10", src: shoukaki, name: "消火器", type: "shoukaki" },
    { id: "cbmy-11", src: shobokiko, name: "消防機庫", type: "shobokiko" },
    { id: "cbmy-12", src: hospital, name: "病院・薬局", type: "hospital" },
    { id: "cbmy-13", src: aed, name: "AED", type: "aed" },
    { id: "cbmy-14", src: garbageStation, name: "ゴミステーション", type: "garbageStation" },
    { id: "cbmy-15", src: bbs, name: "掲示板", type: "bbs" },
    { id: "cbmy-16", src: publicPhone, name: "公衆電話", type: "publicPhone" },
    { id: "cbmy-17", src: vendingMachine, name: "自販機", type: "vendingMachine" },
    { id: "cbmy-18", src: waterWray, name: "水路", type: "waterWray" },
    { id: "cbmy-19", src: dArea, name: "危険箇所", type: "dArea" },
    { id: "cbmy-20", src: dRoad, name: "狭い・危険な道", type: "dRoad" },
    { id: "cbmy-21", src: hazardousArea, name: "危険区域", type: "hazardousArea" },
    { id: "cbmy-22", src: n01, name: "その他", type: "n01" },
    // { id: "cbmy-23", src: "img/marker/m_evacuation-shelter.png", name: "避難所", type: "evacuationShelter" },
    // { id: "cbmy-24", src: "img/marker/m_reservoir.png", name: "ため池", type: "reservoir" },
  ]
  
  export default {
    mapConfig,
    headerTitle,
    markerCategories,
    markerCategory2Img,
    markersInUse,
    newSpotList,
    rShelter,
    eShelter,
    wShelter,
    mPlace,
    park,
    speaker,
    camera,
    shoukasen,
    boukasuisou,
    shoukaki,
    shobokiko,
    hospital,
    aed,
    garbageStation,
    bbs,
    publicPhone,
    vendingMachine,
    waterWray,
    dArea,
    dRoad,
    hazardousArea,
    n01,
    n02,
    n03,
    n04,
    n05,
    n06,
    n07,
    n08,
    n09,
    n10,
    n11,
    n12,
    n13,
    n14,
    n15,
    n16,
    n17,
    n18,
    n19,
    n20,
    evacuationShelter,
    reservoir,
    rShelterP,
    eShelterP,
    wShelterP,
    mPlaceP,
    parkP,
    speakerP,
    cameraP,
    shoukasenP,
    boukasuisouP,
    shoukakiP,
    shobokikoP,
    hospitalP,
    aedP,
    garbageStationP,
    bbsP,
    publicPhoneP,
    vendingMachineP,
    waterWrayP,
    dAreaP,
    dRoadP,
    hazardousAreaP,
    n01P,
    n02P,
    n03P,
    n04P,
    n05P,
    n06P,
    n07P,
    n08P,
    n09P,
    n10P,
    n11P,
    n12P,
    n13P,
    n14P,
    n15P,
    n16P,
    n17P,
    n18P,
    n19P,
    n20P,
    evacuationShelterP,
    reservoirP,
  }
  