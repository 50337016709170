import React from 'react';
import { useHistory } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";

/**
 * パスワード再発行の完了画面
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PasswordRegenerateComplete = props => {
    const history = useHistory();
    
    return (
      <div className="l-wrap">
        <MyAnalytics />

        <HeaderTitle />

        <div className="l-users is-first">
          <div className="l-users--in">
            <div className="row">

              <h1 className="p-ttl">パスワード再設定</h1>
              <p className="u-pb-6 p-info-txt"><span className="p-info-txt__icon"><i
                className="icon icon-check"></i></span><span>パスワードの再設定が完了しました。こちらよりログインしてください。</span></p>

              <div className="u-txt-c">
                <button type={"button"} onClick={() => history.push('/login')} className="c-btn c-btn-solid c-btn-lg">ログイン</button>
              </div>
              
            </div>
          </div>
        </div>
          
      </div>
    )
}

export default PasswordRegenerateComplete;
