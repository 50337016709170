import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";
import {Link} from "react-router-dom";
import {checkHash, getDayReport} from "../store/actions";
import {useDispatch} from "react-redux";

const UserRegisterComplete = props => {
    const history = useHistory();

    const dispatch = useDispatch();

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const postCheckHash = async (hash) => {

        const response = await dispatch(checkHash(hash))
        .then(res => {
            return res;
        }).catch(err => {

            // history.push("/login")
        });
        
        return response
    }
    
    useEffect(() => {

        postCheckHash(query.get('hash'));
        
    }, [])
    
    
    
    
    return (
      <div className="l-wrap">
        <MyAnalytics />

        <HeaderTitle />

          <div className="l-users is-first">
              <div className="l-users--in">

                  <h1 className="p-ttl">登録完了</h1>
                  
                      <h2 className="u-fs-md u-pb-2 u-clr-red u-bold">登録が完了いたしました。</h2>
                      
                      <hr className="u-hr u-mb-6" />

                          <div className="u-txt-c">
                              <button type={"button"} onClick={ () => history.push("/login") } className="c-btn c-btn-solid c-btn-lg">ログイン</button>
                          </div>
                  
              </div>
          </div>
          
      </div>
    )
}

export default UserRegisterComplete;
