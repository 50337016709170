import { SET_LINK_DATA, CLEAR_STATE } from './actions';

const initialState = {
    links: [],
}

const linkDataState = (state = initialState, action) => {
    switch(action.type){
        case SET_LINK_DATA:
            return {
                ...state,
                links: action.payload
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state
    }
}

export default linkDataState;
