import {SET_PPC, SET_GEOJSON, CLEAR_STATE} from './actions';
import env from '../config/env';

const initialState = {
    ppc: null,
    geojson: null
}

const ppcState = (state = initialState, action) => {
    switch(action.type){
        case SET_PPC:
            return {
                ...state,
                ppc: action.payload
            };
        case SET_GEOJSON:
            return {
                ...state,
                geojson: action.payload
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}

export default ppcState;