import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import env from "../config/env";

const MyAnalytics = props => {
    const history = useHistory();
    const initializeGA = (measurementId) => {
        let script1 = document.createElement('script');
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + measurementId;
        script1.async = true;
        document.body.appendChild(script1);
        let script2 = document.createElement('script');
        script2.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${measurementId}');`;
        document.body.appendChild(script2);
    };

    useEffect(() => {
        initializeGA(env.GOOGLE_ANALYTICS_ID);
        const unlisten = history.listen((location) => {
            if (!window.gtag) {
                return
            }
            if (!env.GOOGLE_ANALYTICS_ID) {
                console.log('Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.')
                return
            }
            window.gtag('config', env.GOOGLE_ANALYTICS_ID, { page_path: location.pathname })
        })
        return unlisten
    }, []);

    return null;
}

export default MyAnalytics;
