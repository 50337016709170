import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer } from '../components';
import {fetchUserMarkers, fetchUserDrawing, getDayReport, postPhotoToServer} from '../store/actions';
import { setLoginState, clearState } from '../store/actions';
import LogoImg from '../assets/img/logo.png';
import env from '../config/env';
import {Link} from "react-router-dom";
import LoginFilter from "../atoms/LoginFilter";
import loaderGif from "../assets/img/loader.gif";

const Camera = props => {
  const history = useHistory();
	const dispatch = useDispatch();

	const login = useSelector(state => state.login)

	const token = login.userInfo.token;

	// aspect比が合わない不具合対応このサイトを参考
	// 参考：https://adiary.adiary.jp/0420
	// 目標は縦*横が150万
	const videoWidth  = 1250;
	const videoHeight = 1250;
	const offset      = (videoWidth - videoHeight)/2;

	const [isLoading, setIsLoading] = useState(false);
	
  useEffect(()=>{
		const video = document.querySelector("#camera");

	  video.width  = videoWidth;
	  video.height = videoWidth;
		
		/** カメラ設定 */
		const constraints = {
			audio: false,
			video: {
			width: videoWidth,
			height: videoWidth,
			// facingMode: "user"   // フロントカメラを利用する
			facingMode: env.IS_PC ? "user" : { exact: "environment" }  // フロントカメラとリアカメラで設定形式が異なるので注意
			}
		};
	
		/**
		 * カメラを<video>と同期
		 */
		navigator.mediaDevices.getUserMedia(constraints)
		.then( (stream) => {
			video.srcObject = stream;
			video.onloadedmetadata = (e) => {
			video.play();
			};
		})
		.catch( (err) => {
			console.log(err.name + ": " + err.message);
		});
	}, [])

	const postPhoto = async (options) => {
		
		const response = await dispatch(postPhotoToServer(options))
		.then(res => {
			return res;
		}).catch(err => {

		});

		history.push("/manual_with_photo/"+response.photo_id+"/"+response.output_value)
		
		return response
	}
	
	
	const takePicture = () => {

		// ローディングの表示
		setIsLoading(true);
		
		const video = document.querySelector("#camera");
		
		var canvas = document.createElement("canvas")
		canvas.id = "canvas"
		canvas.width  = videoWidth;
		canvas.height = videoHeight;
		const context = canvas.getContext("2d");


		// 演出的な目的で一度映像を止めてSEを再生する
		video.pause();  // 映像を停止
		// se.play();      // シャッター音
		setTimeout( () => {
			video.play();    // 0.5秒後にカメラ再開
		}, 500);
		
		
		context.drawImage(

			video,
			0, offset, videoWidth, videoHeight,
			0,      0, videoWidth, videoHeight
			
		);
		const png = canvas.toDataURL("image/jpeg")
		canvas.remove()
		const option = {
			method:"post", 
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			},
			png:png
		}


		// 写真を撮影し、画面遷移(awaitができないからかここでphoto_idは取れなかった)
		const photoRes = postPhoto(option)

	}
	
	
	

	return (
		<>
			
			{ isLoading &&
				<div className="l-loading">
					<ul className="p-loader">
						<li><img src={loaderGif} alt="" /></li>
						<li>読み込み中です</li>
					</ul>
				</div>
			}
			
			<div className="l-wrap l-wrap--home">
			
			<LoginFilter />

			<header className="l-header">
				<div className="l-header__in">
					<ul className="p-head">
						<li className="p-head-l"><Link to={"/top"}><i className="icon icon-xmark"></i></Link></li>
						<li className="p-head-c">カメラ</li>
					</ul>
				</div>
			</header>

			<div className="l-camera">

				<p className="p-camera-ttl"><span className="u-fs-11">尿が入った検尿カップを枠内にいれてください</span></p>

				<div className="p-camera-content">
					<div className="p-mask"></div>
					<video id="camera" className="p-video" playsInline={true}></video>
				</div>

			</div>

			<div className="l-foot-btn __camera">
				<div className="l-foot-btn--in __camera">
					<div className="u-txt-c u-mb-xxs">
						<dl>
							<dt className="u-pb-1">
								<button type={"button"} className="c-btn-camera" onClick={() => {
									takePicture()
								}}><i className="icon icon-camera"></i></button>
							</dt>
							<dd><span>撮影</span></dd>
						</dl>
					</div>
				</div>
			</div>
			

			{/*/!* videoタグ側を正方形にしているのでこちらも合わせる *!/*/}
			{/*<div style={{width:"320px", height:"320px", position:"relative"}}>*/}
			{/*	<svg style={{position: "absolute",top: "50%", left: "50%", transform: "translate(-50%, -50%)", width:"100px",height:"100px"}}>*/}
			{/*		<circle cx="50" cy="50" r="50" fillOpacity="0" stroke="#FF0000" />*/}
			{/*	</svg>*/}
			{/*	*/}
			{/*</div>*/}


		</div>
		</>
	);
}

export default Camera;
