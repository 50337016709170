import React from 'react';
import {clearState, setLoginState} from "../store/actions";
import {useDispatch} from "react-redux";
import {useHistory } from 'react-router';

const HeaderTitle = props => {


  const history = useHistory();
  const dispatch = useDispatch();

  const jumpTo = page => {
    history.push(page);
    return;
  };
  
        return (
          <header className="l-header">
              <div className="l-header__in">
                  <ul className="p-head">
                      <li className="p-head-c">ウロノート</li>

                    <li className="p-head-r"><a onClick={() => {
                      dispatch(setLoginState(false));
                      dispatch(clearState());
                      jumpTo("/login");
                    }}><i className="icon icon-logout"></i></a></li>
                    
                  </ul>
              </div>
          </header>)
}

export default HeaderTitle;
