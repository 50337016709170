import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { login } from '../store/actions';
import { MyAnalytics, PageTopButton } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";
import {Link} from "react-router-dom";

const Login = props => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState("");
    
    const dispatch = useDispatch();
    const history = useHistory();
    const execLogin = async () => {
        const response = await dispatch(login(email, password))
            .then(res => {
                return res;
            }).catch(err => {
                return "error";
            });
        if (response === "failed" || response === "error") {
            setPassword("");
            
            setEmailError("認証に失敗しました。")
            
        } else {
            history.push("/top");
        }
    }
    return (
      <div className="l-wrap">
            <MyAnalytics />

            <HeaderTitle />
            
            <div className="l-users is-first">
                <div className="l-users--in">

                    <h1 className="p-ttl">ログイン</h1>
                    <form>
                        <ul>

                            <li className="u-pb-1">
                                <div>
                                    <input type="email" placeholder="メールアドレス" value={email} onChange={e => setEmail(e.target.value)} className="f-w-lg" />
                                </div>
                      
                                { emailError && <span className="f-error">{ emailError }</span> }
  
                            </li>

                            <li className="u-pb-1">
                                <div>
                                    <input type="password" placeholder="パスワード" value={password} onChange={e => setPassword(e.target.value)} className="f-w-lg" />
                                </div>

                            </li>
                        </ul>

                        <div class="u-mb-4">
                            <Link to={"/password_regenerate/index"} class="">パスワードをお忘れの場合</Link>
                        </div>

                        <div class="u-txt-c u-mb-8">
                            <button type={"button"} class="c-btn c-btn-solid c-btn-lg" onClick={() => execLogin()}>ログイン</button>
                        </div>

                        <div class="u-txt-c">
                            <Link to={"/term_of_service"} class="">新規登録</Link>
                        </div>
 
                        </form>
                </div>
            </div>
            
            <PageTopButton />
      </div>
    )
}

export default Login;
