import {CHANGE_USER_GROUP, SET_LOGIN_STATE, CLEAR_STATE} from './actions';
import {SET_USER_STATE} from './actions';
import {SET_GROUPS_STATE} from './actions';

const initialState = {
    isLogin: false,
    userInfo:{
        // id:,
        // email:,
        // name:,
        // username:,
        // token:,
        // grouo:,
    },
}

const loginState = (state = initialState, action) => {
    switch(action.type){
        case SET_LOGIN_STATE:
            return {
                ...state,
                isLogin: action.payload
            }
        case SET_USER_STATE:
            return {
                ...state,
                userInfo: action.payload
            }
        case SET_GROUPS_STATE:
            return {
                ...state,
                groups: action.payload
            }
        case CHANGE_USER_GROUP:
            
            let newUserInfo = { ...state.userInfo}

            newUserInfo.group = action.payload
            newUserInfo.center = {
                lat: action.payload.lat,
                lng: action.payload.lng
            }
            
            return {
                ...state,
                userInfo: newUserInfo
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state
    }
}

export default loginState;
