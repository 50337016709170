import React from 'react';
import { useHistory } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";
import {Link} from "react-router-dom";

const UserRegisterTemporaryComplete = props => {
    const history = useHistory();
    
    return (
      <div className="l-wrap">
        <MyAnalytics />

        <HeaderTitle />

          <div className="l-users is-first">
              <div className="l-users--in">

                  <h1 className="p-ttl">仮登録完了</h1>
                  

                      <h2 className="u-fs-md u-pb-2 u-clr-red u-bold">まだ登録は完了していません。</h2>
                      <p className="u-pb-4 u-clr-red">登録したメールアドレスに、本登録のご案内が届いているのでご確認ください。<br />
                          メール本文中の本登録用URLからログインすると、本登録が完了いたします。
                      </p>
                      <p
                        className="u-pb-4">本登録には有効期限がございますので、本登録のご案内メールが届いておりましたら、すぐにメールのURLをクリックしてログイン画面へお進みください。</p>
                      
                      <hr className="u-hr u-mb-6" />
                      
                          <h2 className="u-fs-md u-pb-2">注意事項</h2>
                          <p className="u-fs-sm u-lh-sm u-pb-10">3分以上たってもメールが届いていない場合は、ご登録されたメールアドレスが間違っている可能性がありますので、再登録をお願いいたします。</p>

                          <div className="u-txt-c">
                              <button type={"button"} onClick={ () => history.push("/login") } className="c-btn c-btn-solid c-btn-lg">ログイン</button>
                          </div>
                  
              </div>
          </div>
          
      </div>
    )
}

export default UserRegisterTemporaryComplete;
