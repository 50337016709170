const initialState = {
  data: [{
    date: "2023/3/13",
    record: [
      {
        type: "hainyo",
        time: "14:09",
        amount: 150,
      },
      {
        type: "suibun",
        time: "13:10",
        amount: 300,
      },
      {
        type: "hainyo",
        time: "03:53",
        amount: 55,
      }
    ]
  }],
  current_data:{}
}

const hainyoDataState = (state = initialState, action) => {
  return state
}

export default hainyoDataState;
