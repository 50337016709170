import React from 'react';

const PageTopButton = props => {
    return (
        <>
            <p className="pagetop"><a href="#"></a></p>
        </>
    )
}

export default PageTopButton;