import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer } from '../components';

import {Link} from "react-router-dom";
import {getDayReport, getReport, registerUser, storeReport} from "../store/actions";
import {useDispatch} from "react-redux";
import LoginFilter from "../atoms/LoginFilter";

const ManualInput = props => {
    const history = useHistory();

  const dispatch = useDispatch();

  const { photoId, reportId, tmpOutputValue } = useParams();

  const [tmpReportId, setTmpReportId] = useState("");
  
  // カメラから来た場合のphoto_id
  const [tmpPhotoId, setTmpPhotoId] = useState("");

  const [reportType, setReportType] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [reportDatetime, setReportDatetime] = useState("");
  const [outputValue, setOutputValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [note, setNote] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [is_day_first_report, setis_day_first_report] = useState(false);
  const [is_while_asleep, setis_while_asleep] = useState(''); // 未選択状態を作るため、初期値はブランク
  const [is_residual_urine, setis_residual_urine] = useState(false);
  const [is_urine_leakage, setis_urine_leakage] = useState(false);
  const [is_urinary_urgency, setis_urinary_urgency] = useState(false);
  const [is_difficulty_urinating, setis_difficulty_urinating] = useState(false);


  const [reportDateError, setReportDateError] = useState("");
  const [reportDatetimeError, setReportDatetimeError] = useState("");
  const [outputValueError, setOutputValueError] = useState("");
  const [inputValueError, setInputValueError] = useState("");
  const [noteError, setNoteError] = useState("");
  
  
  
  // submitボタンが押せない場合はtrue
  const cannotSubmit = (reportType == '1' && is_while_asleep === '');
  
  const toggleReportType = (target) => {
    setReportType(target)
    
    return false;
  }

  const loadReport = async (id) => {
      
    const response = await dispatch(getReport(id))
    .then(res => {
      return res;
    }).catch(err => {
      console.log("err")
    });
    
    return response
  }

  useEffect(() => {

    /*
     * 初期値をセット
     */
    
    const hiduke = new Date();
    const year  = hiduke.getFullYear();
    const month = (hiduke.getMonth()+1).toString().padStart(2, "0"); // ゼロ埋め
    const day   = hiduke.getDate().toString().padStart(2, "0"); // ゼロ埋め

    const hour    = hiduke.getHours().toString().padStart(2, "0"); // ゼロ埋め
    const minutes = hiduke.getMinutes().toString().padStart(2, "0"); // ゼロ埋め
    
    console.log("パラメータ")
    console.log(reportId)
    console.log(photoId)
    console.log(tmpOutputValue)
    
    if (reportId) {



      setTmpReportId(reportId);
      
      // useEffectで非同期関数を呼び
      (async () => {

        const res = await loadReport(reportId);

        setReportType(res.report_type);
        setReportDate(res.report_date);
        setReportDatetime(res.time);
        setOutputValue(res.output_value);
        setInputValue(res.input_value);
        setNote(res.note);
        
        setis_day_first_report(res.is_day_first_report == "1");
        setis_while_asleep(res.is_while_asleep);
        setis_residual_urine(res.is_residual_urine == "1");
        setis_urine_leakage(res.is_urine_leakage == "1");
        setis_urinary_urgency(res.is_urinary_urgency == "1");
        setis_difficulty_urinating(res.is_difficulty_urinating == "1");
        
      })();
      
    } else {
      
      // 新規登録
      setReportType("1");
      setReportDate(year + "-" + month + "-" + day);
      setReportDatetime(hour+":"+minutes);
      
      // URLパラメータから値を取得する
      setOutputValue(tmpOutputValue)
    }
    
    
    

    

  },[]) // 1回だけのuseEffect実行
    
  const execLogin = async () => {
      
      setInputValueError(null);
      setOutputValueError(null);
      setReportDateError(null);
      setReportDatetimeError(null);
      setNoteError(null);
    
      const input = {
        
        reportId : tmpReportId ? tmpReportId : null,
        
        photoId : photoId,
        
        reportType : reportType,
        reportDate : reportDate,
        reportDatetime : reportDatetime,
        outputValue : (reportType == "1") ? outputValue : "",
        inputValue : (reportType == "2") ? inputValue : "",
        note: note,
        is_day_first_report : is_day_first_report,
        is_while_asleep : is_while_asleep,
        is_residual_urine : is_residual_urine,
        is_urine_leakage : is_urine_leakage,
        is_urinary_urgency : is_urinary_urgency,
        is_difficulty_urinating : is_difficulty_urinating,
      }

      const response = await dispatch(storeReport(input))
      .then(res => {
        return res;
      }).catch(err => {
        return "error";
      });
      
      if (response === "failed" || response === "error") {
        // setEmailError("システムでエラーが発生しました。")
        return
      }

      
      if (response.errors) {
  
        if (response.errors.output_value) {
          setOutputValueError(response.errors.output_value)
        }
  
        if (response.errors.input_value) {
          setInputValueError(response.errors.input_value)
        }
        
        if (response.errors.report_date) {
          setReportDateError(response.errors.report_date)
        }

        if (response.errors.report_datetime) {
          setReportDatetimeError(response.errors.report_datetime)
        }
        
      } else {
        history.push("/top");
      }
      
    }

    return (
      <div className="l-wrap l-wrap--home">
        
        <LoginFilter />
        
        <MyAnalytics />


        <header className="l-header">
          <div className="l-header__in">
            <ul className="p-head">
              <li className="p-head-l"><Link to={"/top"}><i className="icon icon-xmark"></i></Link></li>
              <li className="p-head-c">記録</li>
            </ul>
          </div>
        </header>


        <div className="l-tab">
          <ul className="p-tab">
            <li className={ reportType == "1" ? "active" : ""} onClick={() => toggleReportType("1") }><a href="#">排尿</a></li>
            <li className={ reportType == "2" ? "active" : ""} onClick={() => toggleReportType("2") }><a href="#">水分摂取</a></li>
          </ul>
        </div>

        <div className="l-tab-inner active">
          <form>
            
            <div id="urine" className="p-area is-active">
              <dl className="u-pb-1">
                <dt className="u-pb-1">測定日時</dt>
                <dd>
                  <ul className="p-flex">
                    <li><input type="date" value={reportDate} onChange={(e) => {setReportDate(e.target.value); console.log(e.target.value)  }} className="f-w-lg" /></li>
                    <li><input type="time" value={reportDatetime} onChange={(e) => setReportDatetime(e.target.value)} className="f-w-lg" /></li>
                  </ul>
                  
                  { reportDateError && <span className="f-error">{ reportDateError }</span> }
                  { reportDatetimeError && <span className="f-error">{ reportDatetimeError }</span> }
                  
                </dd>
              </dl>

              {
                (reportType == "1") && (
                  <>
                    <dl className="u-pb-3">
                      <dt className="u-pb-1">量（mL）</dt>
                      <dd>
                        <input value={outputValue} onChange={(e) => setOutputValue(e.target.value)} type="text" className="f-w-lg"/>
                        
                        { outputValueError && <span className="f-error">{ outputValueError }</span> }
                        
                      </dd>
                    </dl>

                    <div className="u-pb-5">
                    {
                      is_day_first_report && <button onClick={() => setis_day_first_report( ! is_day_first_report ) } className="c-btn c-btn-active c-btn-xl"><i
                        className="icon icon-check2 u-fs-16 u-pe-1"></i>今日の初回です
                      </button>
                    }
                    {
                      // ※「今日の初回です」を有効にした場合は、自動的に就寝中を「はい」にする処理も実行している。
                      ( ! is_day_first_report) && <button onClick={() => {setis_day_first_report( ! is_day_first_report ); ;setis_while_asleep('1');} } className="c-btn c-btn-line-r c-btn-xl"><i
                        className="icon icon-check2 u-fs-16 u-pe-1"></i>今日の初回です
                      </button>
                    }
                    </div>
                    
                    <ul className="p-toggle-item u-mb-2">
                      <li>就寝中</li>
                      <li className="f-radio">
                        <ul className="f-check-flex">
                          <li>
                            <input id="checkbox01-01" type="radio" name="cb1" 
                                   checked={is_while_asleep == '1'}
                                   onChange={() => setis_while_asleep('1')}
                            />
                            <label htmlFor="checkbox01-01">はい</label>
                          </li>
                          <li><input id="checkbox01-02" type="radio" name="cb1" 
                                     checked={is_while_asleep == '0'}
                                     onChange={() => setis_while_asleep('0')}
                          /><label
                            htmlFor="checkbox01-02">いいえ</label></li>
                        </ul>
                      </li>
                    </ul>
                    
                    { cannotSubmit &&
                      <span className="f-error">※ 就寝中の選択は必須です</span>
                    }

                    
                    <ul className="p-toggle-item u-mb-2">
                      <li>残尿感</li>
                      <li className="f-radio">
                        <ul className="f-check-flex">
                          <li>
                            <input id="is_residual_urine-01" type="radio" name="is_residual_urine"
                                   checked={is_residual_urine == '1'}
                                   onChange={() => setis_residual_urine('1')}
                            />
                            <label htmlFor="is_residual_urine-01">あり</label>
                          </li>
                          <li><input id="is_residual_urine-02" type="radio" name="is_residual_urine"
                                     checked={is_residual_urine == '0'}
                                     onChange={() => setis_residual_urine('0')}
                          /><label
                            htmlFor="is_residual_urine-02">なし</label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    
                    <ul className="p-toggle-item u-mb-2">
                      <li>尿漏れ</li>
                      <li className="f-radio">
                        <ul className="f-check-flex">
                          <li>
                            <input id="is_urine_leakage-01" type="radio" name="is_urine_leakage"
                                   checked={is_urine_leakage == '1'}
                                   onChange={() => setis_urine_leakage('1')}
                            />
                            <label htmlFor="is_urine_leakage-01">あり</label>
                          </li>
                          <li><input id="is_urine_leakage-02" type="radio" name="is_urine_leakage"
                                     checked={is_urine_leakage == '0'}
                                     onChange={() => setis_urine_leakage('0')}
                          /><label
                            htmlFor="is_urine_leakage-02">なし</label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    
                    <ul className="p-toggle-item u-mb-2">
                      <li className="p-toggle-Q"><span className="u-pe-1">尿意切迫感</span>
                        
                        {/* 説明モーダルの表示 */}
                        <Link onClick={() => setShowModal(true)} className="p-modal-info"><i
                          className="icon icon-question u-fs-18 u-clr-link"></i></Link>
                        
                      </li>

                      <li className="f-radio">
                        <ul className="f-check-flex">
                          <li>
                            <input id="is_urinary_urgency-01" type="radio" name="is_urinary_urgency"
                                   checked={is_urinary_urgency == '1'}
                                   onChange={() => setis_urinary_urgency('1')}
                            />
                            <label htmlFor="is_urinary_urgency-01">あり</label>
                          </li>
                          <li><input id="is_urinary_urgency-02" type="radio" name="is_urinary_urgency"
                                     checked={is_urinary_urgency == '0'}
                                     onChange={() => setis_urinary_urgency('0')}
                          /><label
                            htmlFor="is_urinary_urgency-02">なし</label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    
                    <ul className="p-toggle-item u-mb-4">
                      <li className="p-toggle-Q"><span className="u-pe-1">排尿困難</span>
                        
                        {/* 説明モーダルの表示 */}
                        <Link onClick={() => setShowModal(true)} className="p-modal-info"><i
                          className="icon icon-question u-fs-18 u-clr-link"></i></Link>
                      
                      </li>
                      <li className="f-radio">
                        <ul className="f-check-flex">
                          <li>
                            <input id="is_difficulty_urinating-01" type="radio" name="is_difficulty_urinating"
                                   checked={is_difficulty_urinating == '1'}
                                   onChange={() => setis_difficulty_urinating('1')}
                            />
                            <label htmlFor="is_difficulty_urinating-01">あり</label>
                          </li>
                          <li><input id="is_difficulty_urinating-02" type="radio" name="is_difficulty_urinating"
                                     checked={is_difficulty_urinating == '0'}
                                     onChange={() => setis_difficulty_urinating('0')}
                          /><label
                            htmlFor="is_difficulty_urinating-02">なし</label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    
                  </>
                )
              }

              {
                // 水分摂取
                (reportType == "2") && (
                  <>
                    <dl className="u-pb-3">
                      <dt className="u-pb-1">量（mL）</dt>
                      <dd>
                        <input value={inputValue} onChange={(e) => setInputValue(e.target.value)}  type="text" className="f-w-lg"/>
                        
                        { inputValueError && <span className="f-error">{ inputValueError }</span> }
                        
                      </dd>
                    </dl>
                  </>
                )
              }
              
              <dl className="u-mb-4">
                <dt className="u-pb-1">備考（メモ）</dt>
                <dd>
                  <textarea value={note} onChange={e => setNote(e.target.value)} className="f-w-xl" rows="10"></textarea>
                </dd>
              </dl>

              <div className="u-pb-8">
                
                <button type={"button"} 
                        onClick={() => execLogin()}
                        disabled={ cannotSubmit }
                        className={"c-btn c-btn-xl u-mb-2 " + (cannotSubmit ? 'c-btn-disabled' : 'c-btn-solid')} >登録する</button>

                { cannotSubmit &&
                  <span className="f-error">※ 排尿の場合は就寝中の選択は必須です</span>
                }
                
              </div>

              <div className="u-txt-c u-pb-8">
                <Link to={"/top"}>キャンセル</Link>
              </div>
            </div>
            
          </form>
        </div>

        { showModal &&
        <div onClick={() => setShowModal(false)} className="modaal-wrapper modaal-inline" id="modaal_1680452402515944d6ed77d859" style={{backgroundColor:"rgba(0,0,0,0.7)"}}>
          <div className="modaal-outer-wrapper">
            <div className="modaal-inner-wrapper">
              <div className="modaal-container">
                <div className="modaal-content modaal-focus" aria-hidden="false"
                     aria-label="Dialog Window - Close (Press escape to close)" role="dialog" tabIndex="0">
                  <div className="modaal-content-container" role="document">
                    <dl className="p-modal-txt u-pb-4">
                      <dt>尿意切迫感：</dt>
                      <dd>急に尿がしたくなり我慢がむずかしかった</dd>
                    </dl>
                    <dl className="p-modal-txt">
                      <dt>排尿困難：</dt>
                      <dd>尿が出にくい</dd>
                    </dl>
                  </div>
                </div>
                {/* 念の為バツボタンにも閉じるイベントを追加 */}
                <button type="button" className="modaal-close" onClick={() => setShowModal(false)}><span>Close</span></button>
              </div>
            </div>
          </div>
        </div>
        }
        
        
      </div>
    )
}

export default ManualInput;
