import {TOGGLE_MARKER_VISIBLE, CLEAR_STATE, SET_MARKER_VISIBLE} from './actions';
import commonLib from '../config/commonLib';

const initialState = {
    hinanjo: false,
    tameike_kurashiki: false,
    commonShoukasen:false,
    commonBoukasuisou:false,
    regist: false,
    freeDrawing: false
};

// 避難所、ため池、災害碑以外はcommonLibからセット
commonLib.newSpotList.map(category => initialState[category.type] = false);

const markerVisibleState = (state=initialState, action) => {
    switch(action.type){
        case TOGGLE_MARKER_VISIBLE:

            if(action.payload === 'registed') {
                commonLib.newSpotList.map(category => state[category.type] = true);
                return {
                    ...state,
                    regist: true
                }
            }

            // 登録したスポットのチェックは全選択、全解除を兼ねる。
            if (action.payload === 'regist') {
                commonLib.newSpotList.map(category => state[category.type] = !state['regist']);
            }
   
            return {
                ...state,
                [action.payload]: !state[action.payload]
            }
        case SET_MARKER_VISIBLE:
            if( commonLib.newSpotList.some(elem=>elem.type === action.payload.category) ){
                if(action.payload.visibility){
                    return {
                        ...state,
                        [action.payload.category]: action.payload.visibility,
                        regist: true
                    }
                }
            }
            return {
                ...state,
                [action.payload.category]: action.payload.visibility
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}

export default markerVisibleState;
