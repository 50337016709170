import React, {useEffect} from 'react';
import {clearState, getUser, setLoginState} from "../store/actions";
import {useDispatch} from "react-redux";
import {useHistory } from 'react-router';

const LoginFilter = props => {
  
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {

    // useEffectで非同期関数を呼び出す
    (async () => {
      await loginCheck();
    })();
    
  }, [])
  
  const loginCheck = async () => {
    const response = await dispatch(getUser())
    .then(res => {
      return res;
    }).catch(err => {
      history.push("/login")
    });

    if (response == 'error') {

      // ログアウト処理
      dispatch(setLoginState(false));
      dispatch(clearState());

      history.push("/login")
    }

    return response
  }
  
  
  
  return (
    <>
    </>
  )
}

export default LoginFilter;
