import React, {useState} from 'react';
import { useHistory, useParams } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";
import {doPasswordRegeneration} from "../store/actions";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";


const PasswordRegenerate = props => {
  
  const history = useHistory();

  const dispatch = useDispatch();

  const { email, argToken } = useParams();
  
  const [tokenError, setTokenError] = useState("");
  
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [passwordConfirmation, setPasswordCofirmation] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
  
  const regeneratePassword = async () => {

    // エラーメッセージをリセット
    setPasswordError(null);
    setPasswordConfirmationError(null);
    setTokenError(null);
    
    const response = await dispatch(doPasswordRegeneration(argToken, password, passwordConfirmation))
    .then(res => {
      return res;
    }).catch(err => {
      return "error";
    });

    if (response === "failed" || response === "error") {
      setPasswordError("システムでエラーが発生しました。")
    }

    if (response.errors) {

      if (response.errors.token) {
        setTokenError(response.errors.token)
      }
      
      if (response.errors.password) {
        setPasswordError(response.errors.password)
      }
      
      if (response.errors.password_confirmation) {
        setPasswordConfirmationError(response.errors.password_confirmation)
      }
      
    } else {
      history.push("/password_regenerate/complete");
    }
  }
  
  return (
      <div className="l-wrap">
        <MyAnalytics />

        <HeaderTitle />
        
        <div className="l-users is-first">
          <div className="l-users--in">
            <div className="row">

              { ( ! tokenError) ?
                <>

                  <h1 className="p-ttl">パスワード再設定</h1>
                  
                  <p className="u-pb-6 p-info-txt"><span className="p-info-txt__icon"><i className="icon icon-info"></i></span><span>パスワードの再設定を行います。新たなパスワードを2回入力してください。</span></p>
              <hr className="u-hr u-mb-7" />

                <form>
                  <ul className="u-pb-2">
                    <li><span className="u-fs-sm u-clr-sky">メールアドレス</span></li>
                    <li className="u-pb-5">{ email }</li>
                    <li className="u-pb-2"><span className="u-fs-sm">新しいパスワード</span></li>
                    <li>
                      <div>
                        <input type="password" placeholder="パスワード" value={password} onChange={e => setPassword(e.target.value)} className="f-w-lg" />
                      </div>

                      { passwordError && <span className="f-error">{ passwordError }</span> }

                    </li>
                    <li className="u-pb-3"><span className="u-fs-11">（半角の英字と数字を含む、8文字以上の文字列）</span></li>
                    <li className="u-pb-2">
                      <div>
                        <input type="password" placeholder="パスワード(再入力)" value={passwordConfirmation} onChange={e => setPasswordCofirmation(e.target.value)} className="f-w-lg" />
                      </div>

                      { passwordConfirmationError && <span className="f-error">{ passwordConfirmationError }</span> }

                    </li>
                  </ul>

                  <div className="u-txt-c">
                    <button type={"button"} onClick={() => regeneratePassword()} className="c-btn c-btn-solid c-btn-lg">再設定する</button>
                  </div>

                </form>
                </>
                
                :
                
                <>
                <form>
                <ul className="u-pb-2">
                  <li>
                    
                  <span className="f-error">{ tokenError }</span>
                  </li>
                </ul>
                </form>
                  
                  <div className="u-mb-4">
                    <Link to={"/password_regenerate/index"} class="">パスワード再設定をやり直す</Link>
                  </div>
                  
                  
                </>
              }
              
              
            </div>
          </div>
        </div>
         
          
      </div>
    )
}

export default PasswordRegenerate;
