import React from 'react';
import { useHistory } from 'react-router';
import { MyAnalytics } from '../atoms';
import HeaderTitle from "../atoms/HeaderTitle";

const TermOfService = props => {
    const history = useHistory();
    
    return (
      <div className="l-wrap">
            <MyAnalytics />

        <HeaderTitle />

        <div className="l-users is-first">
            <div className="l-users--in">
              <h1 className="p-ttl">利用規約</h1>
              <div className="l-kiyaku">
                <p
                  className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">
                  この規約はウロノート（以下、「本システム」という。）の利用規約（以下「本規約」という。）であり、本システムを利用するにあたっては本規約に記載されている権利および制限事項を遵守するものとする。
                </p>
                
                <h2 className="u-pb-2">1. 定義</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  本規約において使用される用語の定義はそれぞれ次の各号に規定するとおりとする。<br />
                  ①ユーザＩＤ　甲が本システムを利用するにあたり、甲とその他の者を識別するために用いられるメールアドレス。<br />
                  ②パスワード　ユーザIDと組み合わせて、甲が本システムを利用するにあたり、甲とその他の者を識別するために用いられる英字、数字などによる符号。<br />
                  ③利用登録　本システムを利用するために甲が必要とする手続。<br />
                  ④本システム用設備　本システムを提供するにあたり、乙が設置するコンピュータ、通信設備その他の機器及びソフトウェア、及び本システムを提供するための通信回線。<br />
                  ⑤知的財産権　特許権・実用新案権・意匠権・商標権（以下、これらを「産業財産権」という）、並びに産業財産を受ける権利、および、著作権並びに営業秘密をいう。<br />
                  ⑥本システムの利用目的　排尿日誌の記録<br />

                </p>
                
                <h2 className="u-pb-2">2. 知的財産権</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  乙およびその許諾者が著作権その他の知的財産を有する本システムに関し、乙は甲へライセンスするために必要な権利を保有する。甲による本システムの利用に関して、甲には本規約で許諾される以外は何らの権利も発生せず、本システムに関する全ての権利は乙およびその許諾者に留保されるものとする。なお、本システムに関し、乙以外が著作権を有する部分については、別途記載されたライセンス文が本規約に優先して適用される。

                </p>



                <h2 className="u-pb-2">3. 本システムの利用方法</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）甲は、利用登録を行うことにより、甲の端末から、利用登録の有効期間中、乙が提供する本システムを利用することができる。<br />
                  （２）甲は、本システムの利用目的以外では本システムを一切使用してはならない。<br />
                  （３）甲は、アカウント情報を第三者に対して開示、貸与、共有せず、パスワードの適宜変更その他の方法でアカウント情報を第三者に漏えいすることのないよう厳重に管理し、適切に使用するものとする。アカウント情報の管理不備、使用上の過誤、第三者の使用などにより甲または甲以外の者が損害を被った場合、乙は一切の責任を負わないものとする。<br />
                  （４）第三者が甲のアカウント情報を用いて本システムを利用した場合、当該行為は甲の行為とみなされるものとし、甲はかかる利用についての利用料の支払その他の債務一切を負担するものとする。また、当該行為により乙が損害を被った場合、甲は当該損害を補填するものとする。ただし、乙の責に帰すべき事由により甲のアカウント情報が第三者に利用された場合はこの限りではない。<br />

                </p>

                <h2 className="u-pb-2">4. サービス内容などの変更</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  乙は、乙のホームページに掲載またはその他乙所定の方法にて甲に通知することにより、サービス内容を変更することができるものとする。
                  
                </p>

                <h2 className="u-pb-2">5. 本システムの停止</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）乙は、次の各号の場合には本システムの提供の全部または一部を停止することができるものとする。<br />
                  ①戦争、テロ行為、騒乱、暴動、致死的な伝染病の流行を含む天災地変（以下「天災地変」という）その他の不可抗力、第三者による加害行為（サイバーテロなど）によりサービスの提供が不能となったとき<br />
                  ②データセンターの保守・工事その他のやむを得ない事由があるとき<br />
                  ③本システム用設備にかかる通信回線を提供する電気通信事業者（乙を除く）が、当該回線に係る電気通信業務を停止したとき<br />
                  （２）乙は、甲につき次の各号の事由が生じたときは、本システムの提供を停止できるものとする。<br />
                  ①甲が本規約の各条項に違背したとき<br />
                  ②前号のほか、甲の責に帰すべき事由により乙の業務に著しい支障を来たし、またはそのおそれがあるとき<br />
                  （３）前項の場合、乙は、甲に対して、事前にサービスの提供を停止する日、その期間及び停止する理由を通知するものとする。ただし、緊急やむを得ない事由による場合は事後の通知をもって足りるものとする。<br />

                </p>


                <h2 className="u-pb-2">6. 禁止事項</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  甲は、本システムを使用するにあたり、以下の各号に定める事項を行ってはならないものとする。<br />
                  ① 本システムを乙の承諾なく譲渡・転貸し、第三者に本規約に基づき許諾された使用権を譲渡し、再使用権を許諾し、または本規約上の地位を移転すること。<br />
                  ② 本システムの利用方法に定める場合を除き、本システムを複製すること。<br />
                  ③ 本システムについてリバースエンジニアリング、逆アセンブルまたは逆コンパイルすること。<br />
                  ④本システムを変更し、または改造を行うこと。<br />


                </p>


                <h2 className="u-pb-2">7. 損害賠償</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）甲は、本システムの利用に伴い、自己の責に帰すべき事由により第三者に対して損害を与え、または第三者からクレームなどの請求がなされた場合においては、自己の責任と費用をもって処理、解決するものとする。甲が本システムの利用に伴い、第三者から損害を被った場合、または第三者に対してクレームなどの請求を行う場合においても同様とする。<br />
                  （２）本システムを利用して甲が提供または伝送する情報（コンテンツ）については、甲の責任で提供されるものであり、乙はその内容などについていかなる保証も行わず、また、それに起因する損害についてもいかなる責任も負わないものとする。<br />
                  （３）甲は、自己の責に帰すべき事由により乙に損害を与えた場合、乙に対して、当該損害を賠償する責を負うものとする。<br />
                  
                </p>


                <h2 className="u-pb-2">8. 本システム利用のための設備設定・維持</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）甲は、自己の費用と責任において、本システム利用のための環境を設置・維持するものとする。<br />
                  （２）契約者設備及び本システム利用のための環境に不具合がある場合、乙は甲に対して本システムの提供の義務を負わないものとする。<br />


                </p>


                <h2 className="u-pb-2">9. 免責</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）乙は本システムに関して、明示的または黙示的を問わず、商品性及び特定目的への適合性に関する黙示的保証を含むがそれに限られない、いかなる保証も行わないものとする。本システムは「現状有姿」で提供されるものとし、本システムの品質および性能に対するすべてのリスクは甲に存在するものとする。<br />
                  （２）本システムはデータの精度または完全性を保証するものではなく、停電やパソコンの異常などによりデータの欠落やずれが生じる可能性がある。乙は使用または使用不能から生じるデータの喪失、利益の損失、もしくは機会の喪失を含むがそれに限られない結果的、付随的若しくは間接的な損害に対する責任を一切負わないものとする。<br />
                  （３）乙は本システムの使用により甲または第三者が被ったいかなる損害についても一切責任を負わない。ただし、乙の故意または重大な過失による場合はこの限りではない。<br />


                </p>


                <h2 className="u-pb-2">10. 本システムの利用終了</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  甲は、本システムの利用が終了した場合、次の各号に定める措置を速やかに講じなければならないものとする。<br />
                  ①本システムの利用にあたって乙から提供を受けた機器、ソフトウェア及びそれに関わるすべての資料など（当該ソフトウェア及び資料などの全部または一部の複製物を含む。以下同じとする。）のすべてを終了後速やかに乙に返還し、契約者設備に格納されたソフトウェア及びそれに関わる資料などのすべてを、甲の責任で完全に消去するものとする。<br />
                  ②本システムの利用にあたって甲から提供を受けた資料など（資料などの全部または一部の複製物を含む。以下同じとする。）のすべてを終了後速やかに甲に返還し、本システム用設備に記録された資料などのすべてを、乙の責任で完全に消去するものとする。<br />
                  ③本システムを経由し甲から受信したデータの取扱いについては、甲乙間で別途協議の上決定するものとする。<br />


                </p>


                <h2 className="u-pb-2">11. 一般条項</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）本規約のいずれかの条項またはその一部が法律により無効となった場合には、かかる部分は本規約から削除されるものとし、その他の条項は存続するものとする。<br />
                  （２）本システムを海外に持ち出す場合には、甲は日本国外国為替及び外国貿易法、米国輸出管理法およびその他の国の法令を遵守する。<br />



                </p>


                <h2 className="u-pb-2">12. 秘密情報</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）甲及び乙は、本規約にもとづき相手方から開示を受ける技術上・行政上などの情報であって、次の各号に該当するものを秘密情報とする。<br />
                  ①秘密である旨が明示された文書、図面その他の有体物または電子文書・電磁的記録として開示される情報<br />
                  ②秘密である旨を告知した上で口頭により開示される情報であって、口頭による開示後３０日以内に当該情報の内容が秘密である旨を明示された書面により開示されたもの<br />
                  ③本システムにより開示される情報<br />
                  （２）甲及び乙は、互いに秘密情報を善良な管理者の注意をもって管理し、相手方の事前の書面による同意または法令により開示を求められた場合を除き、第三者に開示、公表及び配布をしないものとする。<br />
                  （３）甲及び乙は、秘密情報を本システムの利用目的にのみに使用する。<br />
                  （４）甲及び乙は、前各項の規定にかかわらず、次の各号に該当する情報は、秘密情報として扱わないことを確認する。ただし、秘密情報に該当しないことはこれを主張する側において明らかにしなければならないものとする。<br />
                  ①開示時点で既に公知であった情報、または既に保有していた情報<br />
                  ②開示後、甲及び乙の責に帰すべからざる事由により公知となった情報<br />
                  ③正当な権限を有する第三者から適法に入手した情報<br />
                  ④秘密情報を利用することなく独自に開発した情報<br />
                  ⑤秘密保持義務を課すことなく第三者に開示した情報<br />
                  （５）第２項及び第３項の義務は、本システムの利用終了後も存続するものとする。<br />
                  （６）甲及び乙は、本規約が終了したとき、相手方の求めがあったとき、または本システム提供のために必要がなくなった場合には、相手方の指示に応じ、第１項の秘密情報を記録した媒体及びその複製物を返還または破棄するものとする。開示が電子文書または電磁的記録による場合の取扱い及び破棄処分の方法に関しては甲乙が協議の上決定する。<br />

                </p>


                <h2 className="u-pb-2">13. 個人情報の取扱い</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  （１）乙は、本システムの提供に関連して知った甲の保有する個人情報（「個人情報の保護に関する法律」第２条第１項に規定する情報をいうものとし、以下「個人情報」という）を他に開示、公表、及び配布をせず、乙自身もその個人情報を利用しないものとする。ただし、法令にもとづき開示が要求された場合についてはこの限りではないものとする。<br />
                  （２）乙は、前項の個人情報を善良な管理者の注意をもって厳重に管理するものとし、漏えい防止のための合理的かつ必要な方策を講じるものとする。<br />
                  （３）乙は、本システムの利用が終了したとき、甲の要求があったとき、または本システム利用のために必要がなくなったときは、甲の指示に応じ、第１項の個人情報を記録した媒体及びその複製物を返還または破棄する。開示が電子文書または電磁的記録による場合の返却及び破棄処分の方法に関しては甲乙が協議の上決定する。<br />
                  （４）乙は、前３項に規定するほか、個人情報の取扱い及び管理について、個人情報保護に関する法令の趣旨に従うものとする。<br />


                </p>


                <h2 className="u-pb-2">14. 研究への利用</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">

                  甲は乙が研究などでデータを利用することをあらかじめ同意するものとする。

                </p>



                <h2 className="u-pb-2">15. 準拠法／裁判管轄</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">
                  本規約は日本国の法律に準拠するものとし、本規約に関し紛争が生じた場合は、京都地方裁判所を第一審の専属的合意管轄とする。

                </p>
                
                <h2 className="u-pb-2">附則</h2>
                <p className="u-fs-11 u-lh-sm u-pb-6 u-txt-justify">
                  ２０２３年５月２５日　制定・施行
                </p>





              </div>
            </div>
          </div>


          <div className="l-foot">
            <div className="l-foot--in">
              <div className="u-txt-c u-mb-xxs">
                <button type={"button"} onClick={ () => history.push("/login") } className="c-btn c-btn-line c-btn-lg">同意しない</button>
              </div>
              <div className="u-txt-c">
                <button type={"button"} onClick={ () => history.push("/user_register") }  className="c-btn c-btn-solid c-btn-lg">同意する
                </button>
              </div>
            </div>
          </div>
          
          
      </div>
    )
}

export default TermOfService;
