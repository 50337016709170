import {
    SET_MARKER_DATA, 
    ADD_ADDITIONAL_MARKER, 
    CHANGE_ADDITIONAL_MARKER, 
    REMOVE_ADDITIONAL_MARKER, 
    CONCAT_MARKERS,
    CONCAT_MARKER_TYPES,
    CLEAR_STATE
} from './actions';
import commonLib from '../config/commonLib';

const initialState = {
    markerTypes: [],
    additionals: []
};

commonLib.markerCategories.map(category => initialState[category] = null);

const markerDataState = (state=initialState, action) => {
    switch(action.type){
        case SET_MARKER_DATA:
            return {
                ...state,
                [action.payload.category]: action.payload.data
            }
        case ADD_ADDITIONAL_MARKER:
            return {
                ...state,
                additionals: [
                    ...state.additionals,
                    action.payload
                ]
            }
        case CHANGE_ADDITIONAL_MARKER:
            let changeList = [...state.additionals];
            changeList[action.idx] = action.payload;
            return {
                ...state,
                additionals: changeList
            }
        case REMOVE_ADDITIONAL_MARKER:
            let removeList = [...state.additionals];
            removeList.splice(action.payload, 1);
            return {
                ...state,
                additionals: removeList
            }
        case CONCAT_MARKERS:
            return {
                ...state,
                additionals: action.payload
            }
        case CONCAT_MARKER_TYPES:
            return {
                ...state,
                markerTypes: action.payload
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}

export default markerDataState;
